import React from 'react';
import { Link } from 'gatsby';

import './button.module.scss';

const ButtonWrapper = ({ target, url, theme, children, size }) => {
  const buttonTheme =
    theme === 'bg'
      ? 'button--with-bg'
      : theme === 'bg--dark'
      ? 'button--with-bg button--with-bg-dark'
      : theme === 'text--light'
      ? 'button--text-light'
      : '';
  const buttonSize = size === 'small' ? 'button--small' : '';
  const buttonClasses = `button ${buttonTheme} ${buttonSize}`;

  if (!url) {
    return <button styleName={buttonClasses}>{children}</button>;
  } else {
    if (target === '_blank') {
      return (
        <a
          href={url}
          target={target}
          rel="noreferrer"
          styleName={buttonClasses}
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link to={url} target={target} styleName={buttonClasses}>
          {children}
        </Link>
      );
    }
  }
};

const Button = ({
  text = 'Button text',
  url,
  withArrow,
  target,
  theme,
  size,
}) => {
  return (
    <ButtonWrapper url={url} target={target} theme={theme} size={size}>
      {text}

      {withArrow && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="12"
          viewBox="0 0 13 12"
          styleName="button__arrow"
        >
          <path
            fill="none"
            stroke={`${
              // TODO: Update these to use SASS variables
              theme === 'bg--dark' || theme === 'bg'
                ? '#F5B727'
                : theme === 'text--dark'
                ? 'currentColor'
                : 'currentColor'
            }`}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M0,6 L12,6 M6,0 L12,6 L6,12"
          />
        </svg>
      )}
    </ButtonWrapper>
  );
};

export default Button;
