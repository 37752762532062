import React from 'react';
import { Link, graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';

import './company.module.scss';

import linkedin from '../images/social/linkedin.svg';
import medium from '../images/social/medium.svg';
import twitter from '../images/social/twitter.svg';
import instagram from '../images/social/instagram.svg';
import angellist from '../images/social/angellist.svg';

const Pagination = ({ data, order }) => {
  return (
    <div className={`o-col o-col-xs-6 u-mar-top-s`}>
      {data && (
        <Link
          to={`/companies/${data.uid}`}
          styleName={`pagination pagination--${order}`}
        >
          <span>{order.charAt(0).toUpperCase() + order.slice(1)}</span>
          <h3>{data.name}</h3>
          <p>{data.tagline}</p>
        </Link>
      )}
    </div>
  );
};

const Company = ({ data }) => {
  const pageContent = data.prismicCompany.data;
  const companiesData = data.allPrismicCompanies?.edges[0].node.data.companies;

  let previousCompany;
  let nextCompany;

  if (companiesData) {
    const companies = companiesData.map(company => ({
      name: company.company.document.data.company_name.text,
      tagline: company.company.document.data.company_tagline.text,
      uid: company.company.document.uid,
    }));

    for (let [i, company] of companies.entries()) {
      if (company.name === pageContent.company_name.text) {
        previousCompany = companies[i - 1];
        nextCompany = companies[i + 1];
      }
    }
  }

  // Organize social links into an array
  const socialItems = [];

  for (let item in pageContent.company_social[0]) {
    const url = pageContent.company_social[0][item].url;

    if (url !== '') {
      socialItems.push({
        platform: item,
        url,
      });
    }
  }

  const socialIcon = {
    linkedin,
    twitter,
    instagram,
    medium,
    angellist,
  };

  return (
    <Layout hideGradient={true}>
      <SEO title={pageContent.company_name.text} />

      <div className="u-mar-top-l u-vertical-rhythm">
        <div styleName="company__header">
          <div className="o-container-fluid">
            <Link to="/companies">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
              >
                <path
                  fill="none"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M0,6 L12,6 M6,0 L12,6 L6,12"
                />
              </svg>
              Back to All Companies
            </Link>
          </div>

          <div className="o-container-fluid" styleName="company__title">
            <div className="o-row">
              <div className="o-col o-col-sm-8 o-col-sm-md-6">
                <p className="u-text-medium u-mar-left-xxxs">
                  {pageContent.company_sector}
                </p>
                <h1 className="title--xl">{pageContent.company_name.text}</h1>
              </div>
            </div>
          </div>

          <div
            styleName={`header__image header__image--${pageContent.founder.length}`}
          >
            {pageContent.founder.map((image, i) => (
              <img
                src={image.headshot.fluid.src}
                alt={image.headshot.alt}
                key={i}
              />
            ))}
          </div>
        </div>

        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col o-col-sm-md-3 o-col-md-2 u-pad-top-xxs u-mar-bottom-xxl">
              <img
                src={pageContent.company_logo.url}
                alt={pageContent.company_name.text}
                styleName="sidebar__logo"
              />

              <div styleName="sidebar__tagline">
                {pageContent.company_tagline.text}
              </div>

              <div>
                <ul styleName="sidebar__founders">
                  <li>
                    <strong>Founded by</strong>
                  </li>

                  {pageContent.founder.map((item, i) => (
                    <li key={i}>
                      <a href={item.linkedin.url}>
                        <p>{item.name.text}</p>
                        <p>{item.title}</p>
                      </a>
                    </li>
                  ))}
                </ul>

                {socialItems.length > 0 && (
                  <ul styleName="sidebar__social">
                    {socialItems.map((item, i) => (
                      <li key={i}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <img
                            src={socialIcon[item.platform]}
                            alt={
                              item.platform.charAt(0).toUpperCase() +
                              item.platform.slice(1)
                            }
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                )}

                <Button
                  text={pageContent.company_website.url}
                  url={pageContent.company_website.url}
                  size={'small'}
                  withArrow
                />
              </div>
            </div>

            <div className="o-col o-col-sm-10 o-col-sm-md-8 o-col-xl-6 o-col-sm-md--offset-1">
              <h2 className="title--small u-mar-bottom-l">
                {pageContent.headline.text}
              </h2>

              <div
                dangerouslySetInnerHTML={{ __html: pageContent.full_bio.html }}
              />
            </div>
          </div>
        </div>
      </div>

      {previousCompany || nextCompany ? (
        <div className="o-container-fluid u-mar-bottom-m xs:u-mar-bottom-xl">
          <div className="o-row">
            <Pagination data={previousCompany} order={'previous'} />
            <Pagination data={nextCompany} order={'next'} />
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default withPreview(Company);

export const query = graphql`
  query($uid: String!) {
    allPrismicCompanies {
      edges {
        node {
          uid
          data {
            companies {
              company {
                document {
                  ... on PrismicCompany {
                    uid
                    data {
                      company_name {
                        text
                      }
                      company_tagline {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicCompany(uid: { eq: $uid }) {
      _previewable
      data {
        company_name {
          text
        }
        company_tagline {
          text
        }
        company_logo {
          url
          alt
        }
        company_sector
        company_website {
          url
        }
        company_social {
          medium {
            url
          }
          linkedin {
            url
          }
          instagram {
            url
          }
          twitter {
            url
          }
          angellist {
            url
          }
        }
        founder {
          headshot {
            fluid(maxWidth: 800) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          name {
            text
          }
          title
          linkedin {
            url
          }
        }
        headline {
          text
        }
        full_bio {
          html
        }
      }
    }
  }
`;
